import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  Grid,
  AppBar,
  Typography,
  Hidden,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import DrawerMenu from './DrawerMenu';
import logo_white from '../img/logo-white.svg';
import logo_black from '../img/logo-black.svg';

export default function Navbar({ open, setOpen, color }) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  const useStyles = makeStyles({
    linkStyle: {
      textDecoration: 'none',
      color: `${color}`,
      '&:hover': {
        textDecoration: 'underline',
        color: `${color}`,
      },
    },
    underlined: {
      textDecoration: 'underline',
      color: `${color}`,
    },
  });

  const classes = useStyles();

  return (
    <>
      <AppBar
        color='transparent'
        position='absolute'
        style={{
          boxShadow: 'none',
          zIndex: 100,
          top: 0,
        }}
      >
        <Toolbar className='relative-padding'>
          <Grid container>
            <Grid item xs={5} sm={4}>
              <Link to='/' title='Stetind Advokat' className='logo'>
                <img
                  src={color === 'black' ? logo_black : logo_white}
                  alt='Stetind Advokat'
                  style={{ width: '18em', height: '6em' }}
                />
              </Link>
            </Grid>
            <Hidden smDown>
              <Grid
                container
                item
                xs={7}
                sm={8}
                spacing={6}
                justify='flex-end'
                alignItems='center'
              >
                {data.site.siteMetadata.menuLinks.map((x) => (
                  <Grid item key={x.name}>
                    <Link
                      className={`item ${classes.linkStyle}`}
                      to={x.link}
                      key={x.link}
                    >
                      <Typography variant='h6'>{x.name}</Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid
                container
                item
                xs={7}
                sm={8}
                alignItems='center'
                justify='flex-end'
              >
                <Grid item>
                  <IconButton
                    className={classes.linkStyle}
                    aria-label='menu'
                    onClick={() => setOpen(!open)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <DrawerMenu
            data={data.site.siteMetadata.menuLinks}
            open={open}
            setOpen={setOpen}
          />
        </Toolbar>
      </AppBar>
    </>
  );
}
