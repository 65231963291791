import React from 'react';
import { Link } from 'gatsby';
import {
  Drawer, Grid, IconButton, Typography, withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../img/logo-white.svg';

const linkStyle = {
  textDecoration: 'none',
  color: 'white',
  '&:hover': {
    textDecoration: 'underline',
  },

};

const StyledDrawer = withStyles({
  paper: {
    background: 'black',
  },
})(Drawer);

function DrawerList({ data, open, setOpen }) {
  return (
    <div
      role="presentation"
      style={{ width: '100vw' }}
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <Grid container className="relative-padding" direction="column">
        <Grid item xs={12}>
          <Grid container alignItems="center" direction="row">
            <Grid item xs={6}>
              <Link to="/" title="Stetind Advokat" style={linkStyle}>
                <img
                  src={logo}
                  alt="Stetind Advokat"
                  style={{ width: '10em', height: '6em' }}
                />
              </Link>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <IconButton style={{ color: 'white' }} aria-label="close" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item>
          <Grid container direction="column">
            {data.map((x) => (
              <Grid item style={{ margin: '1.5rem 0.0rem' }}>
                <Link to={x.link} style={linkStyle} key={x.link}>
                  <Typography variant="h6" m={6}>
                    {x.name}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const DrawerMenu = ({ data, open, setOpen }) => (
  <StyledDrawer anchor="right" open={open} onClose={() => setOpen(false)}>
    <DrawerList data={data} open={open} setOpen={setOpen} />
  </StyledDrawer>
);

export default DrawerMenu;
