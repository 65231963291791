import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import logo from '../img/logo-white.svg';

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterContent {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(components/markdown/footer.md)/" }
        }
      ) {
        nodes {
          frontmatter {
            phoneTitle
            phone
            emailTitle
            email
            addressTitle
            address
            extra
          }
        }
      }
    }
  `).allMarkdownRemark.nodes[0].frontmatter;

  return (
    <div className='footer has-background-black'>
      <div className='container pb-6'>
        <div className='pb-6'>
          <Link to='/' title='Stetind Advokat'>
            <img
              src={logo}
              alt='Stetind Advokat'
              style={{ width: '15em', height: '6em' }}
            />
          </Link>
        </div>
        <div className='content'>
          <div className='columns is-tablet is-multiline'>
            <div className='column is-half-tablet is-one-third-desktop'>
              <p className='title is-4 has-text-white'>{data.phoneTitle}</p>
              <p className='subtitle is-6 has-text-white'>{data.phone}</p>
            </div>
            <div className='column is-half-tablet is-one-third-desktop'>
              <p className='title is-4 has-text-white'>{data.emailTitle}</p>
              <p className='subtitle is-6 has-text-white'>{data.email}</p>
            </div>
            <div className='column is-half-tablet is-one-third-desktop'>
              <p className='title is-4 has-text-white'>{data.addressTitle}</p>
              <p className='subtitle is-6 has-text-white'>{data.address}</p>
            </div>
          </div>
        </div>
        <p className='pt-6 has-text-white'>
          Orgnr: 925607622
          <br />
          <br />
          Utvikling og design av{' '}
          <a href='https://www.midtlid.com'>Kim André Midtlid</a>,{' '}
          <a href='https://www.linkedin.com/in/mattistrygstad/'>
            Mattis Trygstad
          </a>{' '}
          og Sebastian Aas.
          <br />
          <br />
          {data.extra}
        </p>
      </div>
    </div>
  );
}
