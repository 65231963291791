import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'
import './all.sass'
import './markdown.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

const TemplateWrapper = ({ children, color }) => {
	const { title, description } = useSiteMetadata()
	const [open, setOpen] = useState(false)
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>{title}</title>
				<meta name="description" content={description} />

				<link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
				<link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-32x32.png`} sizes="32x32" />
				<link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-16x16.png`} sizes="16x16" />

				<link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
				<link rel="manifest" href="/site.webmanifest" />
				<meta name="theme-color" content="#fff" />
				<meta property="og:type" content="business.business" />
				<meta property="og:title" content={title} />
				<meta property="og:url" content="/" />
				<meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
			</Helmet>
			<Navbar open={open} setOpen={setOpen} color={color ? color : 'black'} />
			<div>{children}</div>
			<div className="cookie-consent-container">
				<CookieConsent
					cookieName="gatsby-gdpr-google-analytics"
					location="bottom"
					buttonText="Godta"
					enableDeclineButton
					declineButtonText="Avslå"
					disableStyles
					buttonClasses="btn btn-primary m-2"
					declineButtonClasses="btn btn-secondary m-2"
					contentClasses="p-2"
					containerClasses="maxw-960 p-5"
					flipButtons
				>
					Denne nettsiden bruker cookies. Disse blir brukt for å samle informasjon om hvordan du interagerer med
					nettsiden. Dersom du avslår vil ikke din informasjon bli lagret når du besøker nettsiden.
				</CookieConsent>
			</div>
			<Footer />
		</>
	)
}

export default TemplateWrapper
